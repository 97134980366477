<template>
    <div class="seting">
        <BackBar :pageTit="headTit" />
        <ul>
            <li>
                <p>头像</p>
                <div class="seting_li">
                    <input type="file" accept="image/gif,image/jpeg,image/png,image/jpg" hidden  ref="txFile" @change="inputChange">
                    <div  @click="$refs.txFile.click()">
                        <van-image
                            class="avatar"
                            fit="cover"
                            round
                            :src="user.headimgurl"
                        />
                    </div>
                    <span></span>
                </div>
            </li>
            <li>
                <p>背景墙</p>
                <div class="seting_li">
                    <input type="file" accept="image/gif,image/jpeg,image/png,image/jpg" hidden  ref="bgFile" @change="bgChange">
                    <div  @click="$refs.bgFile.click()">
                        <van-image
                            class="bg"
                            fit="cover"
                            :src="user.bg"
                        />
                    </div>
                    <span></span>
                </div>
            </li>
            <li @click="reviseName">
                <p>昵称</p>
                <div class="seting_li">
                    {{title}}
                    <span></span>
                </div>
            </li>
            <li @click="choseSex">
                <p>性别</p>
                <div class="seting_li">
                    {{sex}}
                    <span></span>
                </div>
            </li>
            <li @click="choseTime">
                <p>生日</p>
                <div class="seting_li">
                    {{dataTime}}
                    <span></span>
                </div>
            </li>
        </ul>
        <!-- 
        <div class="edit_btn">退出登录</div> -->

        <!-- 弹窗 -->
        <van-popup v-model="show" position="bottom" :style="{ height: '35%' }">
            <van-datetime-picker
                type="date"
                title="选择生日"
                :visible-item-count="5"
                :min-date="minDate"
                :max-date="maxDate"
                :formatter="formatter"
                @confirm = "confirm"
                @cancel = 'cancel'
            />
        </van-popup>
    </div>
</template>
<script>
// @ is an alias to /src
import BackBar from "@/components/BackBar.vue";
import Api from '@/api'
import Vue from 'vue'
export default {
    name:'Seting',
    data(){
        return{
            headTit:'基础设置',
            user:{
                headimgurl:'~@/assets/img/link1.png',
                bg:'~@/assets/img/link1.png',
            },
            dataTime:'0000.0.0',
            show:false, // 选择生日的弹窗
            // currentDate: '',
            minDate:new Date(1950,0,1),
            maxDate:new Date(),
            title:'',
            sex:'',

        }
    },
    components: {
        BackBar,
    },
    mounted(){
        var that=this
        Api.go('my_goal',false,(res)=>{
            that.user.headimgurl=res.me.headimgurl
            if(res.me.user_zone_bg){
                that.user.bg=res.me.user_zone_bg
            }
            if(res.me.birthday_at){
                that.dataTime=res.me.birthday_at
            }
            that.title=res.me.title
            that.sex=res.me.sex==1?'男':(res.me.sex==2?'女':'保密')

        })
    },
    methods:{
        // 修改头像
        inputChange () {
            var that=this
            const filex = this.$refs.txFile.files[0]
            var reader = new FileReader();
            reader.onloadend = function () {
                
                Api.go('upload_img',{img:reader.result},(res)=>{

                    that.user.headimgurl = res.src

                    Api.go('change_info',{
                        type:'head',
                        v:res.id
                    },()=>{})
                
                })
            };
            reader.readAsDataURL(filex);




        },
        //修改照片墙
        bgChange(){
            var that=this
            const bgFile = this.$refs.bgFile.files[0]
            var reader = new FileReader();
            reader.onloadend = function () {
                
                Api.go('upload_img',{img:reader.result},(res)=>{

                    that.user.bg = res.src

                    Api.go('change_info',{
                        type:'bg',
                        v:res.id
                    },()=>{})
                
                })
            };
            reader.readAsDataURL(bgFile);

        },
        //修改昵称
        reviseName(){
            this.$router.push('/Nickname')
        },
        //修改性别
        choseSex(){
            this.$router.push('/ChoseSex')
        },
        //修改生日
        choseTime(){
            this.show = true
        },
        formatter(type, val) {
            if (type === 'year') {
                return val + '年';
            }
            if (type === 'month') {
                return val + '月';
            }
            if (type === 'day') {
                return val + '日';
            }
            return val;
        },
        confirm(value){
            let d = new Date(value);
            this.dataTime =  d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
            Api.go('change_info',{
                        type:'birthday',
                        v:this.dataTime
                    },()=>{

                    })
            this.show = false
        },
        cancel(){
            this.show = false
        }
    }
}
</script>
<style lang="less" scoped>
    .seting{
        ul{
            background: #fff;
            padding:0 .32rem;
            li{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: .32rem 0;
                border-bottom: 1px solid rgba(0, 0, 0, .1);
                &:last-child{
                    border-bottom: none;
                }
                p{
                    font-size: .28rem;
                    color: #333;
                }
                .seting_li{
                    display: flex;
                    align-items: center;
                    font-size: .28rem;
                    color: #999;
                    .avatar{
                        width: .8rem;
                        height: .8rem;
                        border-radius: 50%;
                    }
                    .bg{
                        width: 1.4rem;
                        height: .8rem;
                        background: #000000;
                    }
                    span{
                        width: .32rem;
                        height: .32rem;
                        background: url(~@/assets/img/jt_fr.png) no-repeat center top/100% auto;
                        margin-left: .24rem;
                    }
                }
            }
        }
        .edit_btn{
            width: 6.86rem;
            height: .96rem;
            line-height: .96rem;
            background: #F65102;
            border-radius: .48rem;
            font-size: .32rem;
            color: #fff;
            text-align: center;
            position: fixed;
            bottom: 1rem;
            left: .32rem;
        }
    }
</style>